/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export function ClientImages({ images }) {
  return (
    <div className="container mx-auto px-4 py-8">
      <Carousel key={images.length} autoPlay infiniteLoop useKeyboardArrows showThumbs={false}>
        {images.map((image) => (
          <div key={image}>
            <LazyLoadImage
              src={image}
              alt={`Client ${image}`}
              effect="blur"
              width="100%"
              height="auto"
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

ClientImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};
