import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './ProductSwiper.css';

function ProductSwiper({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="product-swiper  min-h-[400px]">
      {images.length > 1 && (
        <button type="button" className="arrow left-arrow" onClick={handlePrevClick}>&#9664;</button>
      )}
      <img src={images[currentIndex]} alt={`Product ${currentIndex + 1}`} className="product-image " />
      {images.length > 1 && (
        <button type="button" className="arrow right-arrow" onClick={handleNextClick}>&#9654;</button>
      )}
    </div>
  );
}

ProductSwiper.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductSwiper;