// App.jsx
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import arTranslation from './locales/ar/translation.json';
import {
  Routes, Route, Navigate,
} from 'react-router-dom';
import { Navbar } from '@/widgets/layout';
import routes from '@/routes';

// Initialize i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      ar: {
        translation: arTranslation,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  // Filter out hidden routes for the Navbar
  const visibleRoutes = routes.filter(route => !route.hidden);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ThemeProvider>
          <div className="container absolute left-2/4 z-10 mx-auto -translate-x-2/4 p-4">
            <Navbar routes={visibleRoutes} />
          </div>
          <Routes>
            {routes.map(
              ({ path, element }) => element
              && <Route key={path} exact path={path} element={element} />,
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
