import { BriefcaseIcon, ChartBarIcon, PlayIcon } from '@heroicons/react/24/solid';

export const contactData = [
  {
    title: 'excellentServices',
    icon: BriefcaseIcon,
    description: 'excellentServices',
  },
  {
    title: 'expandYourMarket',
    icon: ChartBarIcon,
    description: 'expandYourMarket',
  },
  {
    title: 'initiateProgress',
    icon: PlayIcon,
    description: 'initiateProgress',
  },
];

export default contactData;
