import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "@/widgets/layout";
import { ClientImages } from "../widgets/layout/ClientImages";
import { ClientList } from "../widgets/layout/ClientList";

export function Showcase() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const titleClass = isRtl
    ? "text-3xl font-arabic"
    : "text-xl font-semibold text-gray-900";
  const paragraphClass = isRtl ? "text-2xl font-arabic" : "text-gray-700";
  const clientsData = [
    {
      name: t("Client1img"),
      images: [
        "img/IraqiKorean/IMG_3026.webp",
        "img/IraqiKorean/IMG_3029.webp",
        "img/IraqiKorean/IMG_3032.webp",
        "img/IraqiKorean/IMG_3033.webp",
        "img/IraqiKorean/IMG_3034.webp",
        "img/IraqiKorean/IMG_3036.webp",
        "img/IraqiKorean/IMG_3038.webp",
        "img/IraqiKorean/IMG_3040.webp",
        "img/IraqiKorean/IMG_3041.webp",
        "img/IraqiKorean/IMG_3042.webp",
        "img/IraqiKorean/IMG_3044.webp",
        "img/IraqiKorean/IMG_3046.webp",
        "img/IraqiKorean/IMG_3047.webp",
        "img/IraqiKorean/IMG_3049.webp",
        "img/IraqiKorean/IMG_3051.webp",
        "img/IraqiKorean/IMG_3052.webp",
        "img/IraqiKorean/IMG_3053.webp",
        "img/IraqiKorean/IMG_3054.webp",
        "img/IraqiKorean/IMG_3056.webp",
        "img/IraqiKorean/IMG_3057.webp",
        "img/IraqiKorean/IMG_3059.webp",
        "img/IraqiKorean/IMG_3060.webp",
        "img/IraqiKorean/IMG_3061.webp",
        "img/IraqiKorean/IMG_3062.webp",
        "img/IraqiKorean/IMG_3065.webp",
        "img/IraqiKorean/IMG_3066.webp",
        "img/IraqiKorean/IMG_3067.webp",
        "img/IraqiKorean/IMG_3068.webp",
        "img/IraqiKorean/IMG_3070.webp",
        "img/IraqiKorean/IMG_3071.webp",
        "img/IraqiKorean/IMG_3072.webp",
        "img/IraqiKorean/IMG_3073.webp",
        "img/IraqiKorean/IMG_3074.webp",
        "img/IraqiKorean/IMG_3075.webp",
        "img/IraqiKorean/IMG_3077.webp",
        "img/IraqiKorean/IMG_3081.webp",
        "img/IraqiKorean/IMG_3082.webp",
        "img/IraqiKorean/IMG_3087.webp",
        "img/IraqiKorean/IMG_3088.webp",
        "img/IraqiKorean/IMG_3092.webp",
        "img/IraqiKorean/IMG_3093.webp",
        "img/IraqiKorean/IMG_3094.webp",
        "img/IraqiKorean/IMG_3097.webp",
        "img/IraqiKorean/IMG_3098.webp",
        "img/IraqiKorean/IMG_3102.webp",
        "img/IraqiKorean/IMG_3103.webp",
        "img/IraqiKorean/IMG_3104.webp",
        "img/IraqiKorean/IMG_3111.webp",
      ],
    },
    {
      name: t("Client2img"),
      images: [
        "img/Hematology/ADAB2416.webp",
        "img/Hematology/ADAB2418.webp",
        "img/Hematology/ADAB2421.webp",
        "img/Hematology/ADAB2423.webp",
        "img/Hematology/ADAB2425.webp",
        "img/Hematology/ADAB2426.webp",
        "img/Hematology/ADAB2430.webp",
        "img/Hematology/ADAB2431.webp",
        "img/Hematology/ADAB2435.webp",
        "img/Hematology/ADAB2436.webp",
        "img/Hematology/ADAB2441.webp",
        "img/Hematology/ADAB2443.webp",
        "img/Hematology/ADAB2445.webp",
        "img/Hematology/ADAB2449.webp",
        "img/Hematology/ADAB2451.webp",
        "img/Hematology/ADAB2454.webp",
        "img/Hematology/ADAB2456.webp",
        "img/Hematology/ADAB2459.webp",
        "img/Hematology/ADAB2460.webp",
        "img/Hematology/ADAB2463.webp",
        "img/Hematology/ADAB2464.webp",
        "img/Hematology/ADAB2465.webp",
        "img/Hematology/ADAB2473.webp",
        "img/Hematology/ADAB2477.webp",
      ],
    },
    {
      name: t("Client3img"),
      images: [
        "img/GardenCity/gardencity11.webp",
        "img/GardenCity/gardencity12.webp",
        "img/GardenCity/gardencity13.webp",
        "img/GardenCity/gardencity14.webp",
        "img/GardenCity/gardencity3.webp",
        "img/GardenCity/gardencity4.webp",
        "img/GardenCity/gardencity8.webp",
        "img/GardenCity/gardencity9.webp",
      ],
    },
  ];
  const [selectedClient, setSelectedClient] = useState(clientsData[0]);

  const handleClientClick = (client) => {
    setSelectedClient(client);
  };
  const clients = [
    { name: "client3najafstadium" },
    { name: "client4basrasports" },
    { name: "client5alqimmah" },
    { name: "client6karbalarefinery" },
    { name: "client7shuaibarefinery" },
    { name: "client8iraqigerman" },
    { name: "client9alshaab" },
    { name: "client10dijlavillage" },
    { name: "client11foreignaffairs" },
    { name: "client12ministryofoil" },
    { name: "client13sheraton" },
    { name: "client14alkafeel" },
    { name: "client15khatamalhospital" },
    { name: "client16middleeuphratesairport" },
    { name: "client17dralmawali" },
    { name: "client18indoorsportshall" },
    { name: "client19journalistssyndicate" },
    { name: "client20housingministry" },
    { name: "client21educationministry" },
    { name: "client22highereducationministry" },
    { name: "client23healthministry" },
    { name: "client24financeministry" },
    { name: "client25municipalitiesministry" },
    { name: "client26baghdadgov" },
    { name: "client27baghdaduniversity" },
    { name: "client28kufauniversity" },
    { name: "client29almustansiriya" },
    { name: "client30internationaltheater" },
  ];

  return (
    <div dir={isRtl ? "rtl" : "ltr"}>
      <section className="relative block h-[50vh] ">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/background-3.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="container mx-auto text-center">
            <h1 className={`text-white ${titleClass} mb-4 text-4xl`}>
              {t("chooseBabel")}
            </h1>
            <p className={`text-white ${paragraphClass} opacity-95 text-2xl`}>
              {t("partnerWithUs")}
            </p>
          </div>
        </div>
      </section>

      <section
        className="relative bg-white py-16"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="relative mb-6 -mt-40 flex w-full px-4 min-w-0 flex-col break-words bg-white">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="relative flex gap-6 items-start" />
            </div>
          </div>
        </div>
      </section>
      <section className="relative" style={{ backgroundColor: "#f7f7f7" }}>
        <ClientList clients={clientsData} onClientClick={handleClientClick} />
        <div dir="ltr">
          <ClientImages images={selectedClient.images} />
        </div>
      </section>
      <section className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-8">
            {t("OurClients")}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {clients.map((client, index) => (
              <div
                key={index}
                className={`relative p-6 mb-4 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-2 bg-gradient-to-r ${
                  index % 2 === 0
                    ? "from-blue-200 to-blue-400"
                    : "from-green-200 to-green-400"
                } flex items-center justify-center`}
              >
                <div className="text-center">
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {t(client.name)}
                  </h3>
                  <div className="h-1 bg-white w-32 mx-auto mb-4 rounded-full"></div>
                </div>
                <div className="absolute inset-0 bg-primary opacity-0 hover:opacity-20 transition-opacity duration-300 rounded-lg"></div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Showcase;
