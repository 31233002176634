import React from 'react';
import { Footer } from '@/widgets/layout';

export function NotFoundPage() {
  return (
    <>
      <section className="relative block h-[50vh] ">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/background-3.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="absolute inset-0 flex items-center justify-center" />
      </section>
      <section className="relative bg-white py-16">
        <div className="relative mb-6 -mt-40 flex w-full px-4 min-w-0 flex-col break-words bg-white">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="relative flex gap-6 items-start" />
            </div>
          </div>
        </div>
      </section>
      <div className="bg-white text-center py-12">
        <h2 className="text-2xl font-semibold">404: Not Found</h2>
        <p className="mt-2 text-lg text-gray-600">The page you are looking for does not exist.</p>
      </div>
      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default NotFoundPage;
