/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  CardHeader,
  Button,
  Input,
  Textarea,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { FingerPrintIcon } from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { PageTitle, Footer } from "@/widgets/layout";
import { FeatureCard } from "@/widgets/cards";
import { featuresData, contactData } from "@/data";

export function Home() {
  const { t, i18n } = useTranslation();
  const [status, setStatus] = useState("");
  const [fullName, setFullName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(
        "https://babilagency.com/scripts/contact.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            fullName,
            emailAddress,
            subject,
            message,
          }),
        }
      );

      const result = await response.text();
      setStatus(result);
    } catch (error) {
      setStatus(
        "There was an error sending your message. Please try again later."
      );
    }
  };
  const companyLogos = [
    {
      img: "/img/gerflor.webp",
      name: "Gerflor",
      link: "/products",
    },
    {
      img: "/img/logo2.webp",
      name: "Babil",
      link: "/products",
    },
    {
      img: "/img/usgme.webp",
      name: "USG ME",
      link: "/products",
    },
  ];

  return (
    <>
      <div className="relative flex h-screen content-center items-center justify-center pt-16 pb-32">
        <div className="absolute top-0 h-full w-full bg-[url('/img/background-3.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="max-w-8xl container relative mx-auto">
          <div className="flex flex-wrap items-center">
            <div className="ml-auto mr-auto w-full px-4 text-center lg:w-8/12">
              <Typography
                variant="h1"
                color="white"
                className={`mb-6 font-black ${i18n.language === "ar" ? "font-arabic text-4xl" : ""} animate-slideIn`}
              >
                {t("partnersInSuccess")}
              </Typography>
              <Typography
                variant="lead"
                color="white"
                className={`opacity-95 ${i18n.language === "ar" ? "font-arabic text-3xl" : ""} animate-fadeIn`}
              >
                {t("homeDescription")}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <section
        className="-mt-32 bg-white px-4 pb-20 pt-4"
        style={{ backgroundColor: "#F7F7F7" }}
      >
        <div className="container mx-auto">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {(i18n.dir() === "rtl"
              ? [...featuresData].reverse()
              : featuresData
            ).map(({ color, title, icon, description }) => (
              <FeatureCard
                key={title}
                color={color}
                title={t(title)}
                icon={React.createElement(icon, {
                  className: "w-5 h-5 text-white",
                })}
                description={t(description)}
              />
            ))}
          </div>
        </div>
      </section>
      <section
        className="relative px-4 pt-20 pb-48 mb-5"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="container mx-auto">
          <PageTitle section={t("OurProducts")} heading={t("product_heading")}>
            {t("product_description")}
          </PageTitle>
          <div className="mt-24 grid grid-cols-1 gap-12 md:grid-cols-3">
            {companyLogos.map(({ img, name, link }) => (
              <div key={name} className="flex flex-col items-center gap-4 ">
                <Typography
                  variant="h5"
                  color="blue-gray"
                  className={`mb-2 text-3xl ${i18n.dir() === "rtl" ? "font-arabic text-2xl" : ""}`}
                >
                  {t(name)}
                </Typography>
                <img
                  src={img}
                  alt={name}
                  className="w-[auto] h-[auto] rounded-lg shadow-lg transition-all duration-500 ease-in-out transform hover:scale-105 p-1.5"
                  width="351"
                  height="auto"
                />
              </div>
            ))}
          </div>
          <div className="mt-8 flex justify-center">
            <Link to="/products">
              <Button
                variant="gradient"
                className="bg-gradient-to-r from-green-400 to-green-600 transition-all duration-500 ease-in-out transform hover:scale-105 px-8 py-4 text-xl "
              >
                {t("Browse_our_products")}
              </Button>
            </Link>
          </div>
        </div>
      </section>
      <section
        className="-mt-32 relative px-4 pb-20 pt-4"
        style={{
          backgroundColor: "#f7f7f7",
        }}
      >
        <div className="container mx-auto relative z-10">
          <div
            className={`mt-32 flex flex-wrap items-center ${i18n.dir() === "rtl" ? "flex-row-reverse" : ""}`}
          >
            <div className="mx-auto -mt-8 w-full px-4 md:w-5/12">
              <div className="mb-6 inline-flex h-16 w-16 items-center justify-center rounded-full bg-green-900 p-2 text-center shadow-lg">
                <FingerPrintIcon className="h-8 w-8 text-white " />
              </div>
              <Typography
                className={`mb-3 text-2xl font-bold ${i18n.dir() === "rtl" ? "text-right font-arabic text-4xl" : "text-left"}`}
                color="black"
              >
                {t("hero_title")}
              </Typography>
              <Typography
                className={`mb-8 text-xl font-normal ${i18n.dir() === "rtl" ? "text-right font-arabic text-2xl" : "text-left"}`}
                color="black"
              >
                {t("paragraph1")}
                <br />
                <br />
                {t("paragraph2")}
              </Typography>
            </div>
            <div className="mx-auto mt-24 flex w-full justify-center px-4 md:w-4/12 lg:mt-0">
              <Card className="shadow-lg border shadow-gray-500/10 rounded-lg bg-green-800 text-white">
                <CardHeader floated={false} className="relative h-56">
                  <img
                    alt="Card"
                    src="/img/teamwork.webp"
                    className="h-full w-full object-cover"
                  />
                </CardHeader>
                <CardBody>
                  <Typography
                    variant="small"
                    className="font-normal text-white"
                    style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)" }}
                  >
                    Enterprise
                  </Typography>
                  <Typography
                    variant="h5"
                    className={`mb-3 mt-2 font-bold text-white ${i18n.dir() === "rtl" ? "text-right font-arabic text-3xl" : "text-left"}`}
                    style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)" }}
                  >
                    {t("cardbodytitle")}
                  </Typography>
                  <Typography
                    className={`font-normal text-white ${i18n.dir() === "rtl" ? "text-right font-arabic text-2xl" : "text-left"}`}
                    style={{ textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)" }}
                  >
                    {t("cardbodydescription")}
                  </Typography>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </section>
      <section className="relative bg-white py-24 px-4 text-white">
        <img
          src="/img/background-2.webp"
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover"
        />
        <div className="container mx-auto relative z-10">
          <PageTitle
            section={t("section_collab")}
            heading={t("section_collab_heading")}
            sectionClass="text-white"
            headingClass="text-white"
            childrenClass="text-gray"
            className={`${i18n.dir() === "rtl" ? "font-arabic" : ""} text-white`}
          >
            <span className="text-white">
              {t("section_collab_description")}
            </span>
          </PageTitle>

          <div className="mx-auto mt-20 mb-48 grid max-w-5xl grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {contactData.map(({ title, icon, description }) => {
              const titleTranslation = t(`contactData.${title}.title`);
              const descriptionTranslation = t(
                `contactData.${description}.description`
              );

              return (
                <Card
                  key={title}
                  color="transparent"
                  shadow={false}
                  className="text-center text-white bg-green-800 p-2.5"
                  style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1) " }}
                >
                  <div className="mx-auto mb-6 grid h-14 w-14 place-items-center rounded-full bg-green-900 shadow-lg shadow-gray-500/20">
                    {React.createElement(icon, {
                      className: "w-5 h-5 text-white",
                    })}
                  </div>
                  <Typography
                    variant="h5"
                    className={`mb-2 ${i18n.dir() === "rtl" ? "font-arabic text-3xl" : ""} text-white`}
                    style={{
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)",
                      padding: "10px",
                    }}
                  >
                    {titleTranslation}
                  </Typography>
                  <Typography
                    className={`font-normal text-white ${i18n.dir() === "rtl" ? "font-arabic text-2xl" : ""}`}
                    style={{
                      textShadow: "1px 1px 2px rgba(0, 0, 0, 0.8)",
                      padding: "10px",
                    }}
                  >
                    {descriptionTranslation}
                  </Typography>
                </Card>
              );
            })}
          </div>
          <PageTitle
            section={t("contactUs")}
            heading={t("contactUsheading")}
            className={`${i18n.dir() === "rtl" ? "font-arabic" : ""}`}
            sectionClass="text-white"
            headingClass="text-white"
            childrenClass="text-gray"
          >
            <span className="text-white">{t("contactUsdescription")}</span>
          </PageTitle>
          <form
            className="mx-auto w-full mt-12 lg:w-5/12"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col sm:flex-row mb-8 gap-8">
              <div className="relative w-full">
                <Input
                  variant="outlined"
                  size="lg"
                  placeholder=" "
                  id="fullName"
                  aria-label={t("fullName")}
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="peer px-2 py-4"
                />
                <label
                  htmlFor="fullName"
                  className="absolute left-2 -top-2.5 bg-gray-100 border rounded-lg px-1 text-gray-600 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-gray-600"
                >
                  {t("fullName")}
                </label>
              </div>
              <div className="relative w-full">
                <Input
                  variant="outlined"
                  size="lg"
                  placeholder=" "
                  id="emailAddress"
                  aria-label={t("emailAddress")}
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  className="peer px-2 py-4"
                />
                <label
                  htmlFor="emailAddress"
                  className="absolute left-2 -top-2.5 bg-gray-100 border rounded-lg px-1 text-gray-600 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-gray-600"
                >
                  {t("emailAddress")}
                </label>
              </div>
            </div>
            <div className="relative w-full mb-8">
              <Input
                variant="outlined"
                size="lg"
                placeholder=" "
                id="formsubject"
                aria-label={t("formsubject")}
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="peer px-2 py-4"
              />
              <label
                htmlFor="formsubject"
                className="absolute left-2 -top-2.5 bg-gray-100 border rounded-lg px-1 text-gray-600 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-gray-600"
              >
                {t("formsubject")}
              </label>
            </div>
            <div className="relative w-full mb-8">
              <Textarea
                variant="outlined"
                size="lg"
                rows={8}
                placeholder=" "
                id="message"
                aria-label={t("message")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="peer px-2 py-4"
              />
              <label
                htmlFor="message"
                className="absolute left-2 -top-2.5 bg-gray-100 border rounded-lg px-1 text-gray-600 text-sm transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-2.5 peer-focus:text-sm peer-focus:text-gray-600"
              >
                {t("message")}
              </label>
            </div>
            <Button
              type="submit"
              variant="gradient"
              size="lg"
              className={`mt-8 bg-gradient-to-r from-green-400 to-green-600 ${i18n.dir() === "rtl" ? "text-2xl" : ""}`}
              fullWidth
            >
              {t("Contactusbutton")}
            </Button>
            <div className="status-message">{status}</div>
          </form>
        </div>
      </section>
      <div className="bg-white">
        <Footer />
      </div>
    </>
  );
}

export default Home;
