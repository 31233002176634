import React from 'react';

import {
  Home, Products, Showcase, Aboutus, NotFoundPage,
} from '@/pages';

export const routes = [
  {
    name: 'home',
    path: '/',
    element: <Home />,
  },
  {
    name: 'products',
    path: '/products',
    element: <Products />,
  },
  {
    name: 'Showcase',
    path: '/Showcase',
    element: <Showcase />,
  },
  {
    name: 'About us',
    path: '/Aboutus',
    element: <Aboutus />,
  },
  {
    name: 'Not Found',
    path: '*',
    element: <NotFoundPage />,
    hidden: true,
  },
];

export default routes;
