import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Navbar as MTNavbar,
  Collapse,
  Typography,
  IconButton,
  Button,
} from '@material-tailwind/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import logoImage from '../../../public/img/logo.png';

export function Navbar({ routes, action }) {
  const [openNav, setOpenNav] = React.useState(false);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  const [isSwitchingLanguage, setIsSwitchingLanguage] = useState(false);

  const handleLanguageChange = (language) => {
    setIsSwitchingLanguage(true);
    setTimeout(() => {
      changeLanguage(language);
      setIsSwitchingLanguage(false);
    }, 500);
  };
  const navList = (
    <ul className="mb-4 mt-2 flex flex-col gap-2 text-inherit lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 relative top-[10px]">
      {(i18n.dir() === 'rtl' && window.innerWidth >= 960 ? [...routes].reverse() : routes).map(({
        name, path, icon, href, target,
      }, index) => (
        <Typography
          key={name || index}
          as="li"
          variant="h5"
          color="inherit"
          className={`capitalize ${i18n.language === 'ar' ? 'font-arabic text-3xl' : ''} transition duration-500 ease-in-out transform hover:-translate-y-1 hover:text-green-500 hover:scale-110 flex justify-center items-center`}
        >
          {href ? (
            <a
              href={href}
              target={target}
              className="flex items-center gap-1 p-1 font-bold "
            >
              {icon
                && React.createElement(icon, {
                  className: 'w-[18px] h-[18px] opacity-75 mr-1 ',
                })}
              {t(name)}
            </a>
          ) : (
            <Link
              to={path}
              target={target}
              className="flex items-center gap-1 p-1 font-bold text-2xl"
            >
              {icon
                && React.createElement(icon, {
                  className: 'w-[18px] h-[18px] opacity-75 mr-1',
                })}
              {t(name)}
            </Link>
          )}
        </Typography>
      ))}
    </ul>
  );

  return (
    <MTNavbar color="transparent" className="p-3">
      <div className="container mx-auto flex items-center justify-between text-white">
        <Link to="/" className="absolute top-3 left-0">
          <img src={logoImage} alt="Logo" className="relative left-[10px] w-[226px] h-[64px]" width="226" height="64" />
        </Link>
        <div className="hidden lg:flex-grow lg:flex lg:justify-center ">{navList}</div>
        {action && React.cloneElement(action, {
          className: 'hidden lg:inline-block',
        })}
        <div className={`flex flex-col items-center absolute right-1 space-y-1 top-20 lg:top-auto lg:absolute lg:right-1 lg:space-x-2 lg:flex-row lg:space-y-0 ${isSwitchingLanguage ? 'opacity-0' : 'opacity-100'} transition-opacity duration-500`}>
          <Button color="blue-gray" className="bg-transparent text-sm lg:text-xl" onClick={() => handleLanguageChange('en')}>EN</Button>
          <Button color="blue-gray" className="bg-transparent text-sm lg:text-xl" onClick={() => handleLanguageChange('ar')}>العربية</Button>
        </div>
        <IconButton
          variant="text"
          size="sm"
          color="white"
          className="lg:hidden ml-auto text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon strokeWidth={2} className="h-6 w-6" />
          ) : (
            <Bars3Icon strokeWidth={2} className="h-6 w-6" />
          )}
        </IconButton>
      </div>
      <Collapse
        className={`lg:hidden rounded-xl transition duration-500 ease-in-outpx-4 pt-2 pb-4 text-blue-gray-900 z-50 relative fixed w-full mx-auto left-0 ${openNav ? 'bg-white' : 'h-0'}`}
        open={openNav}
      >
        <div className="container mx-auto flex items-center justify-center flex-col">
          {navList}
          {action && React.cloneElement(action, {
            className: 'w-full block',
          })}
        </div>
      </Collapse>
    </MTNavbar>
  );
}
Navbar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string,
      icon: PropTypes.elementType,
      href: PropTypes.string,
      target: PropTypes.string,
    }),
  ).isRequired,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.func,
};
Navbar.displayName = '/src/widgets/layout/navbar.jsx';

export default Navbar;
