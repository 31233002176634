import React, { useState, useEffect } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import downloadIcon from "../../public/img/download.svg";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Footer } from "../widgets/layout/footer";
import "./products.css";
import ProductSwiper from "./Products/ProductSwiper";
import babilData from "./Products/itemsData/babil.json";
import gerflorData from "./Products/itemsData/gerflor.json";
import usgMeData from "./Products/itemsData/usg_me.json";
import loadProductTranslations from "./Products/loadProductTranslations";

function Tabs({ children }) {
  return <div className="tabs">{children}</div>;
}

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
};

function TabsContent({ children, isActive }) {
  if (!isActive) {
    return null;
  }
  return (
    <div className={`tabs-content ${isActive ? "active" : ""}`}>{children}</div>
  );
}

TabsContent.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
};

function TabsList({ children }) {
  return <div className="tabs-list">{children}</div>;
}

TabsList.propTypes = {
  children: PropTypes.node.isRequired,
};

function TabsTrigger({ children, onClick, isActive }) {
  return (
    <button
      type="button"
      className={`tabs-trigger ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

TabsTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

function Card({ children }) {
  return <div className="card">{children}</div>;
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

function CardContent({ children }) {
  return <div className="card-content">{children}</div>;
}

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
};

function CardDescription({ children }) {
  return <p className="product-description ">{children}</p>;
}

CardDescription.propTypes = {
  children: PropTypes.node.isRequired,
};

function CardFooter({ children }) {
  return (
    <div className="card-footer flex items-center justify-between w-full">
      {children}
    </div>
  );
}

CardFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

function CardHeader({ children }) {
  return <div className="card-header min-h-[80px]">{children}</div>;
}

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

function CardTitle({ children }) {
  return <h3 className="text-lg font-bold text-[#16A851]">{children}</h3>;
}

CardTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

function Button({ children, onClick, variant }) {
  return (
    <button type="button" onClick={onClick} className={`button ${variant}`}>
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

Button.defaultProps = {
  variant: "",
};

function convertEscapedCharsToHtml(str) {
  return str
    .replace(/\\n/g, "<br>")
    .replace(/\\t/g, "&emsp;")
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .replace(/\\\\/g, "\\");
}

function ProductCard({ product }) {
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const keywords = [
    "Product Description",
    "Specifications",
    "Dimensions",
    "Features",
    "Installation",
    "Overview",
    "Health Benefits",
    "Specs",
    "المواصفات",
    "وصف المنتج",
    "الأبعاد",
    "الميزات",
    "مواصفات ",
    "نظرة عامة",
    "الفوائد الصحية",
    "الأداء",
  ];
  useEffect(() => {
    const loadTranslations = async () => {
      const lang = i18n.language || "en";
      setLoading(true);
      await loadProductTranslations(lang);
      setLoading(false);
    };

    loadTranslations();
  }, [i18n.language]);

  const boldKeywords = (text, keywords) => {
    let result = text;
    keywords.forEach((keyword) => {
      const regex = new RegExp(`(${keyword})`, "gi");
      result = result.replace(regex, "<strong>$1</strong>");
    });
    return result;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const description = convertEscapedCharsToHtml(t(product.description));
  const boldedDescription = boldKeywords(description, keywords);
  const isRtl = i18n.dir() === "rtl";

  return (
    <div dir={isRtl ? "rtl" : "ltr"}>
      <Card className="w-full ">
        <CardHeader className="min-h-10">
          <CardTitle>{t(product.name)}</CardTitle>
        </CardHeader>
        <CardContent>
          {product.images && product.images.length > 0 && (
            <ProductSwiper images={product.images} />
          )}
          <CardDescription>
            {isExpanded ? (
              <span dangerouslySetInnerHTML={{ __html: boldedDescription }} />
            ) : (
              <span className="min-h-[200px] block">
                <span
                  dangerouslySetInnerHTML={{
                    __html: `${boldedDescription.slice(0, 100)}...`,
                  }}
                />
              </span>
            )}
          </CardDescription>
        </CardContent>
        <CardFooter className="flex items-center justify-between w-full">
          <Button onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <ChevronUp /> : <ChevronDown />}
          </Button>
          {product.pdf && (
            <Button
              onClick={() => window.open(product.pdf, "_blank")}
              className="flex items-center ml-auto"
            >
              <img
                src={downloadIcon}
                alt="Download"
                className="mr-2 h-[68px]"
              />
            </Button>
          )}
        </CardFooter>
      </Card>
    </div>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string.isRequired,
    pdf: PropTypes.string,
  }).isRequired,
};

function SubcategoryContent({ products }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

SubcategoryContent.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string),
      description: PropTypes.string.isRequired,
      pdf: PropTypes.string,
    })
  ).isRequired,
};

export function Products() {
  const { t, i18n } = useTranslation();
  const [activeCategory, setActiveCategory] = useState("babil");
  const [activeSubcategory, setActiveSubcategory] = useState(
    babilData.subcategories[0].id
  );
  const [categories, setCategories] = useState([
    {
      id: "babil",
      name: "Babil",
      data: babilData,
      loaded: true,
    },
    {
      id: "gerflor",
      name: "Gerflor",
      loaded: false,
      data: null,
    },
    {
      id: "usg-me",
      name: "USG ME",
      loaded: false,
      data: null,
    },
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleCategoryClick = (categoryId) => {
    setSearchQuery(""); // Reset search query
    const updatedCategories = categories.map((cat) => {
      if (cat.id === categoryId && !cat.loaded) {
        const data = categoryId === "gerflor" ? gerflorData : usgMeData;
        return { ...cat, loaded: true, data };
      }
      return cat;
    });

    setCategories(updatedCategories);
    setActiveCategory(categoryId);

    const activeCat = updatedCategories.find((cat) => cat.id === categoryId);
    if (activeCat.loaded) {
      setActiveSubcategory(activeCat.data.subcategories[0].id);
    }
  };

  useEffect(() => {
    const loadTranslations = async () => {
      setLoading(true);
      await loadProductTranslations(i18n.language);
      setLoading(false);
    };
    loadTranslations();
  }, [i18n.language]);

  useEffect(() => {
    if (searchQuery) {
      // Load all data sets for search
      const loadAllDataSets = () => {
        if (!categories[1].loaded) {
          setCategories((prevCategories) =>
            prevCategories.map((cat) =>
              cat.id === "gerflor"
                ? { ...cat, loaded: true, data: gerflorData }
                : cat
            )
          );
        }
        if (!categories[2].loaded) {
          setCategories((prevCategories) =>
            prevCategories.map((cat) =>
              cat.id === "usg-me"
                ? { ...cat, loaded: true, data: usgMeData }
                : cat
            )
          );
        }
      };

      loadAllDataSets();

      // Normalize and filter products based on the search query
      const allProducts = categories
        .filter((category) => category.loaded)
        .flatMap((category) =>
          category.data.subcategories.flatMap((subcat) => subcat.products)
        );

      const normalizedProducts = allProducts.map((product) => ({
        ...product,
        name: t(product.name),
        description: t(product.description),
      }));

      const searchResult = normalizedProducts.filter(
        (product) =>
          product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          product.description.toLowerCase().includes(searchQuery.toLowerCase())
      );

      setFilteredProducts(searchResult);
    } else {
      setFilteredProducts([]);
    }
  }, [searchQuery, categories, i18n.language]);

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <section className="relative block h-[50vh]">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/background-3.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">{t("Our Products")}</h1>
        </div>
      </section>
      <section className="relative bg-white py-4  flex items-center justify-center">
        <div className="container mx-auto px-4 flex justify-center">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder={t("Search Products...")}
            className="search-bar w-full max-w-md p-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            autoComplete="new-password"
            name="searchQuery"
          />
        </div>
      </section>
      <section className="relative bg-white py-16 min-h-screen">
        <div className="container mx-auto px-4">
          {searchQuery ? (
            filteredProducts.length ? (
              <SubcategoryContent products={filteredProducts} />
            ) : (
              <div>{t("No products found.")}</div>
            )
          ) : (
            <Tabs>
              <TabsList className="mb-8">
                {categories.map((category) => (
                  <TabsTrigger
                    key={category.id}
                    isActive={activeCategory === category.id}
                    onClick={() => handleCategoryClick(category.id)}
                  >
                    {t(category.name)}
                  </TabsTrigger>
                ))}
              </TabsList>
              {categories.map((category) => (
                <TabsContent
                  key={category.id}
                  isActive={activeCategory === category.id}
                >
                  {category.loaded ? (
                    <>
                      {searchQuery ? (
                        filteredProducts.length ? (
                          <SubcategoryContent products={filteredProducts} />
                        ) : (
                          <div>{t("No products found.")}</div>
                        )
                      ) : (
                        <Tabs>
                          <TabsList className="mb-8">
                            {category.data.subcategories.map((subcat) => (
                              <TabsTrigger
                                key={subcat.id}
                                isActive={activeSubcategory === subcat.id}
                                onClick={() => setActiveSubcategory(subcat.id)}
                              >
                                {t(subcat.name)}
                              </TabsTrigger>
                            ))}
                          </TabsList>
                          {category.data.subcategories.map((subcat) => (
                            <TabsContent
                              key={subcat.id}
                              isActive={activeSubcategory === subcat.id}
                            >
                              <SubcategoryContent products={subcat.products} />
                            </TabsContent>
                          ))}
                        </Tabs>
                      )}
                    </>
                  ) : (
                    <div>{t("Please select a category to load data.")}</div>
                  )}
                </TabsContent>
              ))}
            </Tabs>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

Products.propTypes = {};

export default Products;
