import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaIndustry,
  FaCogs,
  FaMedal,
  FaShippingFast,
  FaHeadset,
  FaWarehouse,
  FaLightbulb,
} from "react-icons/fa";
// eslint-disable-next-line import/no-unresolved, import/extensions
import { PageTitle, Footer } from "@/widgets/layout";

export function Aboutus() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === "ar";
  const titleClass = isRtl
    ? "text-3xl font-arabic"
    : "text-xl font-semibold text-gray-900";
  const paragraphClass = isRtl ? "text-2xl font-arabic" : "text-gray-700";

  const certificates = [
    {
      id: "usg-boral",
      image: "/img/USGBORAL.webp",
      name: "USG Boral Certificate of Appreciation",
    },
    {
      id: "usg-me",
      image: "/img/USGME_cert.webp",
      name: "Authorized Distributor Certificate",
    },
    {
      id: "gerflor",
      image: "/img/Gerflor_cert.webp",
      name: "Gerflor Certificate of Appreciation",
    },
  ];

  return (
    <div style={{ backgroundColor: "#f7f7f7" }}>
      <section className="relative block h-[50vh] ">
        <div className="bg-profile-background absolute top-0 h-full w-full bg-[url('/img/background-3.webp')] bg-cover bg-center" />
        <div className="absolute top-0 h-full w-full bg-black/60 bg-cover bg-center" />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="container mx-auto text-center mt-20">
            <h1
              className={`text-white text-4xl font-bold mb-4 lg:text-4xl ${i18n.dir() === "rtl" ? "text-center font-arabic text-2xl" : "text-center"}  lg:text-4xl`}
            >
              {t("AboutusTitle")}
            </h1>
          </div>
        </div>
      </section>
      <section className="relative py-16">
        <div className="relative mb-6 -mt-40 flex w-full px-4 min-w-0 flex-col break-words ">
          <div className="container mx-auto">
            <div className="flex flex-col lg:flex-row justify-between">
              <div className="relative flex gap-6 items-start" />
            </div>
          </div>
        </div>
      </section>
      <div className="max-w-5xl mx-auto px-4 sm:px-0">
        <p
          className={`text-black text-xl mx-auto text-center opacity-95 ${i18n.dir() === "rtl" ? "text-center font-arabic text-2xl" : "text-center"} text-[1em] lg:text-xl`}
        >
          {t("AboutusParagraph")}
        </p>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-8">
          {certificates.map((certificate) => (
            <div key={certificate.id}>
              <img
                src={certificate.image}
                alt={certificate.name}
                className="w-full h-auto max-w-[850px] mx-auto"
              />
              <h3 className="text-gray-500 text-lg mt-2 text-center">
                {certificate.name}
              </h3>
            </div>
          ))}
        </div>
        <div dir={isRtl ? "rtl" : "ltr"} className="mt-20">
          <PageTitle
            section={t("whyChooseUs")}
            heading={t("partnersInSuccess")}
          >
            {t("elevateYourProjects")}
          </PageTitle>

          <div className="container mx-auto px-4 py-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaIndustry
                  className="mb-4 text-green-300 mx-auto"
                  size="1.5em"
                />
                <h3 className={`${titleClass} mb-4`}>
                  {t("extensiveIndustryExperience")}
                </h3>
                <p className={paragraphClass}>
                  {t("industryExperienceDescription")}
                </p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaCogs className="mb-4 text-green-300 mx-auto" size="1.5em" />
                <h3 className={`${titleClass} mb-4`}>
                  {t("comprehensiveSolutions")}
                </h3>
                <p className={paragraphClass}>{t("solutionsDescription")}</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaMedal className="mb-4 text-green-300 mx-auto" size="1.5em" />
                <h3 className={`${titleClass} mb-4`}>
                  {t("commitmentToExcellence")}
                </h3>
                <p className={paragraphClass}>{t("commitmentDescription")}</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaShippingFast
                  className="mb-4 text-green-300 mx-auto"
                  size="1.5em"
                />
                <h3 className={`${titleClass} mb-4`}>
                  {t("professionalDelivery")}
                </h3>
                <p className={paragraphClass}>{t("deliveryDescription")}</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaHeadset
                  className="mb-4 text-green-300 mx-auto"
                  size="1.5em"
                />
                <h3 className={`${titleClass} mb-4`}>
                  {t("technicalSupport")}
                </h3>
                <p className={paragraphClass}>{t("supportDescription")}</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaWarehouse
                  className="mb-4 text-green-300 mx-auto"
                  size="1.5em"
                />
                <h3 className={`${titleClass} mb-4`}>
                  {t("wideRangeOfProducts")}
                </h3>
                <p className={paragraphClass}>{t("productsDescription")}</p>
              </div>
              <div className="bg-white shadow-lg rounded-lg p-6">
                <FaLightbulb
                  className="mb-4 text-green-300 mx-auto"
                  size="1.5em"
                />
                <h3 className={`${titleClass} mb-4`}>
                  {t("empoweringSuccess")}
                </h3>
                <p className={paragraphClass}>{t("successDescription")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <Footer />
      </div>
    </div>
  );
}

export default Aboutus;
