import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  Typography,
  IconButton,
} from '@material-tailwind/react';
import { useTranslation } from 'react-i18next';

export function FeatureCard({
  color, icon, title, description,
}) {
  const { i18n } = useTranslation();

  return (
    <Card className="rounded-lg shadow-lg shadow-gray-500/10 transform transition-all duration-300 ease-in-out hover:scale-105 hover:-translate-y-2">
      <CardBody className="px-8 text-center">
        <IconButton
          variant="gradient"
          size="lg"
          color={color}
          className="pointer-events-none mb-6 rounded-full"
          aria-label={title}
        >
          {icon}
        </IconButton>
        <Typography variant="h5" className={`mb-2 ${i18n.language === 'ar' ? 'font-arabic text-3xl' : ''}`} color="blue-gray">
          {title}
        </Typography>
        <Typography className={`font-normal text-blue-gray-600 ${i18n.language === 'ar' ? 'font-arabic text-2xl' : 'text-base'}`}>
          {description}
        </Typography>
      </CardBody>
    </Card>
  );
}

FeatureCard.defaultProps = {
  color: 'blue',
};

FeatureCard.propTypes = {
  color: PropTypes.oneOf([
    'blue-gray',
    'gray',
    'brown',
    'deep-orange',
    'orange',
    'amber',
    'yellow',
    'lime',
    'light-green',
    'green',
    'teal',
    'cyan',
    'light-blue',
    'blue',
    'indigo',
    'deep-purple',
    'purple',
    'pink',
    'red',
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
};

FeatureCard.displayName = '/src/widgets/layout/feature-card.jsx';

export default FeatureCard;
