import {
  CurrencyDollarIcon, TruckIcon, ShieldCheckIcon,
} from '@heroicons/react/24/solid';

export const featuresData = [
  {
    color: 'green',
    title: 'Trading & Marketing',
    icon: CurrencyDollarIcon,
    description:
      'Specializes in trading and marketing building materials, particularly focusing on finishing and decoration.',
  },
  {
    color: 'green',
    title: 'Supply Solutions',
    icon: TruckIcon,
    description:
      'Provides comprehensive solutions for various projects by leveraging extensive experience and technical support from represented companies.',
  },
  {
    color: 'green',
    title: 'High Standards',
    icon: ShieldCheckIcon,
    description:
      'Ensures the success of client projects by executing them according to the highest standards with the support of experienced staff and technical expertise.',
  },
];

export default featuresData;
