import i18n from 'i18next';

const loadProductTranslations = async (language) => {

  if (language === 'en') {
    const items_en = await import('/src/locales/en/items_en.json');
    i18n.addResourceBundle('en', 'translation', items_en.default, true, true);
  } else if (language === 'ar') {
    const items_ar = await import('/src/locales/ar/items_ar.json');
    i18n.addResourceBundle('ar', 'translation', items_ar.default, true, true);
  }

};

export default loadProductTranslations;
