/* eslint-disable react/react-in-jsx-scope */
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

export function PageTitle({
  section,
  heading,
  children,
  sectionClass,
  headingClass,
  childrenClass,
}) {
  return (
    <div className="mx-auto w-full px-4 text-center lg:w-6/12">
      <Typography variant="lead" className={`font-semibold ${sectionClass}`}>
        {section}
      </Typography>
      <Typography variant="h2" className={`my-3 ${headingClass}`}>
        {heading}
      </Typography>
      <Typography variant="lead" className={childrenClass}>
        {children}
      </Typography>
    </div>
  );
}

PageTitle.propTypes = {
  section: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  sectionClass: PropTypes.string,
  headingClass: PropTypes.string,
  childrenClass: PropTypes.string,
};

PageTitle.defaultProps = {
  sectionClass: "",
  headingClass: "",
  childrenClass: "",
};

PageTitle.displayName = "/src/widgets/layout/page-title.jsx";

export default PageTitle;
