/* eslint-disable import/prefer-default-export */
import React from "react";
import PropTypes from "prop-types";

export function ClientList({ clients, onClientClick }) {
  return (
    <div className="container mx-auto px-4 py-8">
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {clients.map((client, index) => (
          <button
            key={client.id || index}
            type="button"
            className="bg-gradient-to-r from-blue-400 to-blue-600 shadow-lg rounded-lg p-4 cursor-pointer text-white hover:from-blue-500 hover:to-blue-700 transform hover:scale-105 transition-transform duration-200 ease-in-out"
            onClick={() => onClientClick(client)}
            onKeyDown={(event) => {
              if (event.key === "Enter" || event.key === " ") {
                onClientClick(client);
              }
            }}
          >
            <h3 className="text-xl font-semibold text-center">{client.name}</h3>
          </button>
        ))}
      </ul>
    </div>
  );
}

ClientList.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ).isRequired,
  onClientClick: PropTypes.func.isRequired,
};
